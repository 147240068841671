var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"bg-white"},[_c('div',{staticClass:"category__bg @@class-bg"},[_c('CategoryShare',{attrs:{"dealId":_vm.deal.dealId,"rowId":_vm.deal.rowId}}),_c('CategoryStar'),_c('div',{staticClass:"tourism-category__place"},[_c('div',{staticClass:"tourism-category__image-wrapper"},[_c('img',{staticClass:"tourism-category__image",attrs:{"src":_vm.generateDealImagePath(_vm.deal) || '/assets/img/rome2.png',"alt":""}})]),_c('b',{staticClass:"tourism-category__place-name"},[_vm._v(_vm._s(_vm.deal.destinationName ? _vm.deal.destinationName : _vm.deal.rowName))])])],1),_c('div',{staticClass:"category__info tourism-category__info"},[_c('p',{staticClass:"fs14"},[_c('b',[_vm._v(_vm._s(_vm.$t('TOURISM.DES_VACATION_FOR_NIGHTS', { DES: _vm.deal.destinationName, NUM: _vm.deal.dealLength, })))])]),_c('p',{staticClass:"fs14"},[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.deal.departureDate))+"-"+_vm._s(_vm.calcEndDate(_vm.deal.departureDate, _vm.deal.dealLength))+" ")]),_c('p',{staticClass:"fs16"},[_vm._v(" "+_vm._s(_vm.$t('TOUR_ORDER.ACCOMMODATION'))+": "+_vm._s(_vm.deal.rowName)+" ")]),_c('rate-star',{attrs:{"starNumber":_vm.deal.hotelRating * 1}}),(_vm.deal.dealPricePerPerson || _vm.deal.price)?_c('div',{staticClass:"category__price-wrapper"},[_c('span',{staticClass:"category__price"},[_vm._v(_vm._s(_vm.$t('COMMON.PRICE'))+":  ")]),_c('span',{staticClass:"direction-right"},[_c('b',{staticClass:"category__current"},[_vm._v(_vm._s(_vm.$t('CURRENCY.' + _vm.deal.currency)))]),_c('b',{staticClass:"category__price-value"},[_vm._v(_vm._s(_vm.deal.dealPricePerPerson ? _vm.deal.dealPricePerPerson : _vm.deal.price))])])]):_vm._e(),(_vm.deal.type == 'ORGANIZED_TOUR')?_c('router-link',{attrs:{"to":{
          name: 'TourismOrganized',
          params: { id: _vm.deal.dealId, rowId: _vm.deal.rowId, destinationId: _vm.deal.destination_id, dealType: _vm.deal.type },
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
return [_c('a',{staticClass:"link category__button tourism-category__button",attrs:{"href":href}},[_vm._v(" "+_vm._s(_vm.$t('TOURISM.CHOOSE_VACATION_PACKAGE'))+" ")])]}}],null,false,2578014414)}):_c('router-link',{attrs:{"to":{
          name: 'TourismProduct',
          params: { id: _vm.deal.dealId, rowId: _vm.deal.rowId, destinationId: _vm.deal.destination_id, dealType: _vm.deal.type },
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
return [_c('a',{staticClass:"link category__button tourism-category__button",attrs:{"href":href}},[_vm._v(" "+_vm._s(_vm.$t('TOURISM.CHOOSE_VACATION_PACKAGE'))+" ")])]}}])})],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }