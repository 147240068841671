<template>
<div>
  <div class="bg-white">
    <div class="category__bg @@class-bg">
      <CategoryShare
        :dealId="deal.dealId"
        :rowId="deal.rowId"
      ></CategoryShare>
      <CategoryStar></CategoryStar>
      <div class="tourism-category__place">
        <div class="tourism-category__image-wrapper">
          <img
            class="tourism-category__image"
            :src="generateDealImagePath(deal) || '/assets/img/rome2.png'"
            alt
          />
        </div>
        <b class="tourism-category__place-name">{{
          deal.destinationName ? deal.destinationName : deal.rowName
        }}</b>
      </div>
    </div>

    <div class="category__info tourism-category__info">
      <p class="fs14">
        <b>{{
          $t('TOURISM.DES_VACATION_FOR_NIGHTS', {
            DES: deal.destinationName,
            NUM: deal.dealLength,
          })
        }}</b>
      </p>
      <p class="fs14">
        {{ deal.departureDate | formatDate }}-{{
          calcEndDate(deal.departureDate, deal.dealLength)
        }}
      </p>
      <p class="fs16">
        {{ $t('TOUR_ORDER.ACCOMMODATION') }}: {{ deal.rowName }}
      </p>
      <rate-star :starNumber="deal.hotelRating * 1"></rate-star>
      <div
        v-if="deal.dealPricePerPerson || deal.price"
        class="category__price-wrapper"
      >
        <span class="category__price">{{ $t('COMMON.PRICE') }}: &nbsp;</span>
        <span class="direction-right">
          <b class="category__current">{{ $t('CURRENCY.' + deal.currency) }}</b>
          <b class="category__price-value">{{
            deal.dealPricePerPerson ? deal.dealPricePerPerson : deal.price
          }}</b>
        </span>
      </div>
      <router-link
        v-if="deal.type == 'ORGANIZED_TOUR'"
        :to="{
          name: 'TourismOrganized',
          params: { id: deal.dealId, rowId: deal.rowId, destinationId: deal.destination_id, dealType: deal.type },
        }"
        v-slot="{ href }"
      >
        <a :href="href" class="link category__button tourism-category__button">
          {{ $t('TOURISM.CHOOSE_VACATION_PACKAGE') }}
        </a>
      </router-link>
      <router-link
        v-else
        :to="{
          name: 'TourismProduct',
          params: { id: deal.dealId, rowId: deal.rowId, destinationId: deal.destination_id, dealType: deal.type },
        }"
        v-slot="{ href }"
      >
        <a :href="href" class="link category__button tourism-category__button">
          {{ $t('TOURISM.CHOOSE_VACATION_PACKAGE') }}
        </a>
      </router-link>
    </div>
  </div>
</div>
</template>

<script>
import RateStar from '../RateStar';
import CategoryShare from '../CategoryShare';
import CategoryStar from '../tourism/CategoryStar';

import tourismMixin from '../../utility/tourism_mixin'

export default {
  name: 'TourismUnit',
  mixins: [tourismMixin],
  components: {
    RateStar,
    CategoryShare,
    CategoryStar
  },
  props: {
    deal: {
      type: Object,
      required: true,
    },
  }
};
</script>

<style lang="scss">
.tourism-carousel_unit {
  padding: 0 10px;
}
</style>
